import * as React from "react";

import Layout from "../components/Layout";

const RefundPolicyPage = () => {
  return (
    <Layout
      pageTitle="Refund Policy"
      pageDescription="Refund Policy for the Gatsby Store"
    >
      <section className="policy-section">
        <div class="container page-content">
          <h1 class="page-title">Refund Policy</h1>
          <p>Our Return and Refund Policy was last updated 09 February 2024</p>
          <p>Thank you for shopping at computerhut.club.</p>
          <p>
            If for any reason, you are not completely satisfied with a purchase,
            we invite you to review our policy on refunds and returns.
          </p>
          <p>
            The following terms are applicable for any products that you’ve
            purchased from us.
          </p>
          <h3>Interpretation and Definitions</h3>
          <h4>Interpretation</h4>
          <p>
            The words in which the initial letter is capitalised have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in the plural.
          </p>
          <h4>Definitions</h4>
          <p>For the purposes of this Return and Refund Policy:</p>
          <p>
            “Company” (referred to as either "the Company", "We", "Us" or "Our"
            in this Agreement) refers to Computer Hut
          </p>
          <p>“Goods” refers to the items offered for sale.</p>
          <p>“Orders” means a request by you to purchase goods from us.</p>
          <p>
            “Website” refers to computerhut.club, accessible from
            https://computerhut.club
          </p>
          <p>
            “You” means the individual accessing or using the service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the service, as applicable.
          </p>
          <h3>Your Order Cancellation Rights</h3>
          <p>
            You are entitled to cancel your order within 14 days without giving
            any reason for doing so.
          </p>
          <p>
            The deadline for canceling an Order is 14 days from the date on
            which you received the goods or on which a third party you have
            appointed, who is not the carrier, takes possession of the product
            delivered.
          </p>
          <p>
            In order to exercise your right of cancellation, you must inform us
            of your decision by means of a clear statement. You can inform us of
            your decision by:
          </p>
          <p>By sending us an email: computerhutchd@yahoo.co.in</p>
          <p>
            We will reimburse you no later than 14 days from the day on which we
            receive the returned Goods. We will use the same means of payment as
            you used for the Order, and you will not incur any fees for such
            reimbursement.
          </p>
          <h3>Conditions for Returns</h3>
          <p>
            In order for the Goods to be eligible for a return, please make sure
            that:
          </p>
          <p>
            The goods were purchased in the last 14 days
            <br />
            The goods are in the original packaging
            <br />
            The following goods cannot be returned:
            <br />
            The supply of goods is made to your specifications or clearly
            personalized.
            <br />
            The supply of goods which according to their nature are not suitable
            to be returned, deteriorate rapidly or where the date of expiry is
            over.
            <br />
            The supply of goods that are not suitable for return due to health
            protection or hygiene reasons and were unsealed after delivery.
            <br />
            The supply of goods which are, after delivery, according to their
            nature, inseparably mixed with other items.
          </p>
          <p>
            We reserve the right to refuse returns of any merchandise that does
            not meet the above return conditions at our sole discretion.
          </p>
          <h3>Returning Goods</h3>
          <p>
            You are responsible for the cost and risk of returning the goods to
            Us. You should send the goods to the following address:
          </p>
          <p>&nbsp;</p>
          <p>
            We cannot be held responsible for goods damaged or lost in return
            shipment. Therefore, we recommend an insured and trackable mail
            service. We are unable to issue a refund without actual receipt of
            the goods or proof of received return delivery.
          </p>
          <h3>Contact Us</h3>
          <p>
            If you have any questions about our Returns and Refunds Policy,
            please contact us:
          </p>
          <p>Contact Details: computerhutchd@yahoo.co.in</p>
        </div>
      </section>
    </Layout>
  );
};

export default RefundPolicyPage;